<template>
    <v-row class="text-center py-12" >
      <v-col cols="12">
          <v-icon 
          class="icon black--text"
          size="70"
          dense>fas fa-check-circle</v-icon>
      </v-col>

      <v-col cols="12" class="mb-2">
        <h1
            :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']" 
            class="font-weight-bold mb-2">
          Nos Valeurs
        </h1>
        <br>
        <h2 
            :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']"
            class="font-italic">
          "Il n’ y a pas de limitation de vitesse sur le chemin de l’excellence". <br> SHUMACHER
        </h2>
      </v-col>

      <v-col
        class="mb-3"
        cols="12"
      >
        <p 
          :class="[$vuetify.breakpoint.smAndDown ? '': 'headline']"
          class=" font-weight-bold">
          Notre mission est de transmettre sans 
          faille et sans cesse un savoir et un savoir faire et assurer d’une manière viable et 
          durable le perfectionnement pour tendre constamment vers l’EXCELLENCE.
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <v-row justify="center">
          <v-col cols="6"
            md="3">
            <v-icon 
            size="70"
            color="amber darken-1">fas fa-gavel</v-icon>
             <h4 
             :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
             class="test">Nature Juridique</h4> 
                <h5 
                :class="[$vuetify.breakpoint.smAndDown ? 'display-0 headline': 'display-1']"
                class=" ">SARL</h5>
            </v-col>
          <v-col cols="6"
            md="3">
            <v-icon 
            size="70"
            color="cyan lighten-1">fas fa-plus-circle</v-icon>
             <h4 
             :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
             class="test"> Création</h4>
                <h5 
                :class="[$vuetify.breakpoint.smAndDown ? 'display-0 headline': 'display-1']"
                class=" ">Agrément N°153</h5>
            </v-col>
          <v-col cols="6"
            md="3">
            <v-icon 
            size="70"
            color="light-blue darken-1">fas fa-users</v-icon>
              <h4 
              :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
              class="test">Effectif</h4>
            <h5 
            :class="[$vuetify.breakpoint.smAndDown ? 'display-0 headline': ' display-1']"
            class=" ">10 Personnes Administratif</h5>
          </v-col>
          <v-col cols="6"
            md="3">
            <v-icon 
            size="70"
            color="deep-purple lighten-1">fas fa-border-all</v-icon>
              <h4 
              :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
              class="test">Superficie</h4>
                <h5 
                :class="[$vuetify.breakpoint.smAndDown ? 'display-0 headline': 'display-1']"
                class=" ">450m²</h5>
            </v-col>
        </v-row>
      </v-col>

            <v-row>
                <v-col
                    class="text-center mx-auto"
                    cols="12"
                >
                    <h2 
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
                    class="font-italic font-weight-bold">
                    Agréée par le Ministère de la Formation et de l’Enseignement Professionnels
                    </h2>
                </v-col>
            </v-row>
        
        <v-col
            class="text-center mx-auto mt-5"
            cols="12"
            >
            <v-btn
                class="align-self-end"
                elevation="5"
                fab
                dark
                x-large
                @click="$vuetify.goTo('#qsse')"
                >
                <v-icon>fas fa-chevron-down</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'NosValeurs',
}
</script>